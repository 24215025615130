<template>
  <div id="detail">
    <div class="static_bg">
      <p>
        {{ list.state_desc }}
        <!-- <b >剩{{list.delay_time | time(1)}}天自动确认  待收货</b> -->
        <!-- <span>剩{{list.add_time }}</span> -->
      </p>
    </div>
    <div class="order_info flex-container">
      <div class="order_info_me flex1">
        <div class="me flex-container">
          <img src="@image/order/order_pos.png" />
          <span>{{ list.reciver_name }}</span>
          <b>{{ list.reciver_phone }}</b>
        </div>
        <p>地址：{{ list.reciver_addr }}</p>
      </div>
      <!-- <order-btn class='cancel'>修改地址</order-btn> -->
    </div>

    <div class="detail_pro_list">
      <div
        class="middel"
        v-for="item in list.goods_list"
        @click.stop="goShop(item.store_id, item.goods_id)"
      >
        <div class="flex-container">
          <!-- <img :src="`https://image.shop.19jiang.cn/home/store/goods/2/${item.goods_image}`" class="pro_img"> -->
          <img :src="item.image_url" class="pro_img" />
          <div class="info">
            <h2>{{ item.goods_name }}</h2>
            <span class="sku">数量:{{ item.goods_num }}个</span>
            <!-- <b class='active'>新人首单特价</b> -->
          </div>
          <div class="price">
            <p><i>￥</i>{{ item.goods_pay_price }}</p>
            <b
              style="text-decoration: line-through"
              v-if="item.goods_pay_price != item.goods_price"
              >￥{{ item.goods_price }}</b
            >
            <b>x{{ item.goods_num }}</b>
            <!-- <mt-button v-if="item.refund=='1'" plain size="small" v-on:click="showRefund(item.rec_id)">售后</mt-button> -->
          </div>
        </div>

        <!-- <templace v-if='item.order_state == 30'>
            <div class="flex-container" style='justify-content: flex-end;' v-if="item.refund=='1'" >
              <orderBtn  class='endOrder' @click='goRefund(item.order_id,item.rec_id)'>申请售后</orderBtn>
              <order-btn @click='goDetail(item.extend_refund.refund_id ,item.extend_refund.refund_type)' class='cancel'   v-if="item.refund=='0' && item.extend_refund && item.extend_refund.refund_state != 3">售后中</order-btn>
              <order-btn class='cancel' @click='goDetail(item.extend_refund.refund_id ,item.extend_refund.refund_type)'  v-if="item.refund=='0' && item.extend_refund && item.extend_refund.refund_state == 3">退款完成</order-btn>
            </div>
          </templace> -->

        <div
          class="flex-container"
          style="justify-content: flex-end"
          v-if="item.refund == '1' && !item.extend_refund"
        >
          <template>
            <orderBtn
              class="endOrder"
              @click="goOne_Refund(item.order_id, item.rec_id)"
              v-if="list.order_state == 20"
              >申请退款</orderBtn
            >
            <orderBtn
              class="endOrder"
              @click="goRefund(item.order_id, item.rec_id)"
              v-else
              >申请售后</orderBtn
            >
          </template>
          <orderBtn
            class="endOrder"
            style="margin-left: 0.3rem"
            @click="evaluate(item.rec_id)"
            v-if="item.if_evaluation == 0"
            >去评价</orderBtn
          >
        </div>

        <div
          class="flex-container"
          style="justify-content: flex-end"
          v-if="item.refund == '0' && item.extend_refund"
        >
          <orderBtn
            @click="
              goDetail(
                item.extend_refund.refund_id,
                item.extend_refund.refund_type
              )
            "
            style="cursor: pointer"
            v-if="item.extend_refund.refund_state == 1"
            >{{ list.order_state == 20 ? "退款" : "售后" }}中(1/3)</orderBtn
          >
          <orderBtn
            @click="
              goDetail(
                item.extend_refund.refund_id,
                item.extend_refund.refund_type
              )
            "
            style="cursor: pointer"
            v-if="item.extend_refund.refund_state == 2"
            >{{ list.order_state == 20 ? "退款" : "售后" }}中(2/3)</orderBtn
          >
          <orderBtn
            @click="
              goDetail(
                item.extend_refund.refund_id,
                item.extend_refund.refund_type
              )
            "
            style="cursor: pointer"
            v-if="
              item.extend_refund.seller_state == 2 &&
                item.extend_refund.refund_state == 3
            "
            >{{ list.order_state == 20 ? "退款" : "售后" }}完成</orderBtn
          >
        </div>

        <div
          class="flex-container"
          style="justify-content: flex-end"
          v-if="item.refund == '1' && item.extend_refund"
        >
          <orderBtn
            class="endOrder"
            v-if="item.extend_refund.seller_state == 3"
            @click="goOne_Refund(item.order_id, item.rec_id)"
            style="padding: 0 0.5rem"
            >不同意(重新申请)</orderBtn
          >
        </div>

        <!-- <div class="flex-container" style='justify-content: flex-end;' v-if="item.refund=='0'" >
            <orderBtn  class='cancel' >退款完成</orderBtn>
          </div> -->
      </div>
    </div>

    <div
      class="middel"
      v-for="zengpin in list.zengpin_list"
      v-if="list.zengpin_list"
      @click.stop="goShop(zengpin.store_id, zengpin.goods_id)"
    >
      <div class="flex-container">
        <!-- <img :src="`https://image.shop.19jiang.cn/home/store/goods/2/${item.goods_image}`" class="pro_img"> -->
        <img
          :src="
            'https://image.shop.19jiang.cn/home/store/goods/2/' +
              zengpin.goods_image
          "
          class="pro_img2"
        />
        <div class="info">
          <h2 class="h2"><b class="zengpin">赠</b>{{ zengpin.goods_name }}</h2>
          <span class="sku">数量:{{ zengpin.goods_num }}个</span>
          <!-- <b class='active'>新人首单特价</b> -->
        </div>
        <div class="price">
          <p><i>￥</i>{{ zengpin.goods_pay_price }}</p>
          <b
            style="text-decoration: line-through"
            v-if="zengpin.goods_pay_price != zengpin.goods_price"
            >￥{{ zengpin.goods_price }}</b
          >
          <b>x{{ zengpin.goods_num }}</b>
          <!-- <mt-button v-if="item.refund=='1'" plain size="small" v-on:click="showRefund(item.rec_id)">售后</mt-button> -->
        </div>
      </div>
    </div>

    <div class="detail_price" v-if="list.extend_order_common">
      <div class="flex-container">
        <span class="flex1">商品总额</span>
        <b v-if="list.extend_order_common.voucher_price != null"
          >￥{{
            Number(list.goods_amount) +
              Number(list.extend_order_common.voucher_price)
          }}</b
        >
        <b v-else>￥{{ list.goods_amount }}</b>
      </div>
      <div class="flex-container">
        <span class="flex1">邮费</span>
        <b>￥{{ list.shipping_fee }}</b>
      </div>
      <div
        class="flex-container"
        v-if="list.extend_order_common.voucher_price != null"
      >
        <span class="flex1">优惠券</span>
        <b>-￥{{ list.extend_order_common.voucher_price }}</b>
      </div>
      <div class="flex-container">
        <p>
          应付款：<b><i>￥</i>{{ list.order_amount }}</b>
        </p>
      </div>
    </div>
    <div class="order_id">
      <div class="order_id_li" style="margin-bottom: 0.94737rem">
        <p>店铺：{{ list.store_name }}</p>
      </div>
      <div class="order_id_li flex-container">
        <p @click="doCopy(list.order_sn)">订单编号：{{ list.order_sn }}</p>
        <span @click="doCopy(list.order_sn)">复制</span>
      </div>
      <div class="order_id_li flex-container" v-if="list.shipping_code">
        <p @click="doCopy(list.shipping_code)">
          快递编号：{{ list.shipping_code }}
        </p>
        <span @click="doCopy(list.shipping_code)">复制</span>
      </div>
      <div class="order_id_li" style="margin-bottom: 0.94737rem">
        <p>下单时间：{{ list.add_time }}</p>
      </div>
      <div class="order_id_li">
        <p>支付方式：{{ list.payment_name }}</p>
      </div>
    </div>

    <div class="go_mode flex-container">
      <order-btn
        class="cancel"
        v-if="refund_all && refund_all.refund_state == 3"
        @click="goDetail(refund_all.refund_id, refund_all.refund_type)"
        >退款完成</order-btn
      >
      <order-btn
        class="cancel"
        v-if="refund_all && refund_all.refund_state != 3"
        @click="goDetail(refund_all.refund_id, refund_all.refund_type)"
        >退款中</order-btn
      >
      <order-btn
        class="cancel"
        v-if="list.if_buyer_cancel"
        @click="cancel(list.order_id)"
        >取消订单</order-btn
      >
      <!-- <order-btn class='cancel'   v-if="list.state_desc == '交易完成'" >再次购买</order-btn> -->
      <order-btn
        class="cancel"
        v-if="list.if_deliver"
        @click="deliver(list.shipping_code, list.reciver_phone)"
        >查看物流</order-btn
      >
      <order-btn
        class="endOrder"
        v-if="list.if_receive"
        @click="receive(list.order_id)"
        >确认收货</order-btn
      >
      <!-- <order-btn
        class="endOrder"
        v-if="list.if_evaluation"
        @click="evaluate(list.order_id)"
        >去评价</order-btn
      > -->

      <order-btn v-if="list.state_desc == '交易完成'">交易完成</order-btn>

      <order-btn
        class="cancel"
        v-if="list.if_refund_cancel"
        @click="refund(list.extend_order_goods, list.order_id)"
        >申请退款</order-btn
      >
      <!-- <order-btn class='cancel'   v-if='list.state_desc  == "已取消"' @click="cancel(list.order_id)">取消订单</order-btn> -->
      <order-btn
        class="endOrder"
        v-if="list.state_desc == '已取消' || list.state_desc == '待发货'"
        >{{ list.state_desc }}</order-btn
      >
      <!-- <order-btn class='endOrder'   v-if='list.state_desc  == "已取消" || list.state_desc  == "待发货"'>联系客服</order-btn> -->
      <order-btn
        class="endOrder"
        v-if="list.state_desc == '待付款'"
        @click="goPay(list.pay_sn)"
        >去付款</order-btn
      >
      <!-- <order-btn class='endOrder'   v-if='list.refund_list'>取消售后</order-btn> -->
    </div>
  </div>
</template>
<script>
import { pay } from "@get/pay";
import { post } from "@get/http";
import orderBtn from "../components/orderBtn.vue";
import { Dialog, Toast } from "vant";
import { mapState } from "vuex";
import { getCancelOrder, getReceiveOrder, getOrderInfo } from "../js/";
// import 'vant/lib/dialog/style';

export default {
  name: "",
  data: () => ({
    list: [],
    refund_all: false,
    submitFrom: {
      uniapp: 0, // 是否是小程序
      pay_type: "pay_new", // 订单支付显示的数据
      payment_info: {
        password: "", // 支付密码
        rcb_pay: 0, // 是否使用充值卡进行支付
        pd_pay: 0, // 是否使用预存款进行支付
        payment_code: "alipay_h5", // 支付方式
      },
    },
    payment_list: {}, //可以支付的列表
  }),
  components: {
    orderBtn,
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
    }),
  },
  methods: {
    evaluate(rec_id) {
      //去评价
      this.$router.push({ name: "OrderReview", query: { rec_id } });
    },
    deliver(orderId, phone) {
      this.$router.push({
        name: "OrderMail",
        query: {
          mailid: orderId,
          phone,
        },
      });
      // window.location.href = "https://m.kuaidi100.com/result.jsp?nu=" + orderId;
    },
    refund(product_list, order_id) {
      //售后
      // this.$router.push({ name: 'MemberRefundForm', query: { order_id } })  //旧版本
      this.$router.push({
        name: "OrderServiceReason",
        query: { order_id },
      });
    },
    // evaluate(orderId) {
    //   this.$router.push({ name: "MemberOrderEvaluate", params: { order_id: orderId } });
    // },
    async goPay(pay_sn) {
      // 支付
      let { pay_info } = await post("/memberbuy/pay", {
        data: {
          pay_sn,
        },
      });
      this.payment_list = pay_info?.payment_list[0];
      this.submitFrom.payment_info.payment_code = this.payment_list.payment_code;
      if (this.payment_list.payment_code == "wxpay_minipro") {
        wx.miniProgram.redirectTo({
          url:
            "../pay/pay?action=" +
            this.submitFrom.pay_type +
            "&key=" +
            this.token +
            "&pay_sn=" +
            pay_sn +
            "&password=" +
            this.submitFrom.payment_info.password +
            "&rcb_pay=" +
            this.submitFrom.payment_info.rcb_pay +
            "&pd_pay=" +
            this.submitFrom.payment_info.pd_pay +
            "&payment_code=" +
            this.submitFrom.payment_info.payment_code,
        });
        return;
      }

      pay(
        pay_sn,
        this.submitFrom.pay_type,
        this.submitFrom.payment_info,
        this.token,
        this.submitFrom.uniapp
      )
        .then((result) => {
          Toast("正在准备支付");
          document.write(result);
        })
        .catch(function(error) {
          if (error.message) {
            Toast(error.message);
          } else {
            console.error(error);
            // document.write(error);
          }
        });

      // this.$router.push({
      //   name: "Buy",
      //   query: { pay_sn: paySn, pay_type: "pay_new" },
      // });
    },

    goDetail(refund_id, refund_type) {
      this.$router.push({
        name: "OrderServiceDetail",
        query: { refund_id, refund_type },
      });
    },
    goShop(store_id, goods_id) {
      this.$router.push({ name: "HomeGoodsdetail", query: { goods_id } });
    },

    cancel(order_id) {
      // 取消订单
      Dialog.confirm({
        title: "十九匠商城通知",
        message: "确定要取消该订单吗？",
      }).then(async () => {
        await getCancelOrder(order_id);
        this.$router.go(0);
      });
    },
    doCopy: function(copy) {
      this.$copyText(copy).then(
        function(e) {
          Toast("微信:已复制");
        },
        function(e) {
          Toast("微信:复制失败");
        }
      );
    },
    goRefund(order_id, rec_id) {
      this.$router.push({
        name: "OrderServiceChooese",
        query: { order_id, rec_id },
      });
    },
    goOne_Refund(order_id, order_goods_id) {
      // 单个退款
      this.$router.push({
        name: "OrderServiceReason",
        query: { type: 3, order_id, order_goods_id },
      });
    },
    receive(orderId) {
      // console.log(orderId)
      Dialog.confirm({
        title: "十九匠商城通知",
        message: "确定该订单已收货吗？",
      }).then(async () => {
        await getReceiveOrder(orderId);
        this.$router.go(0);
      });
    },
  },

  async created() {
    Toast.clear();
    Toast.loading({
      message: "加载中...",
      loadingType: "spinner",
      overlay: true,
      forbidClick: true,
      duration: 0,
    });

    let { refund_all, order_info } = await getOrderInfo(
      this.$route.query.order_id
    );
    this.refund_all = refund_all;
    this.list = order_info;
    Toast.clear();
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
// @use "sass:math";
#detail {
  width: 100%;
  overflow: hidden;
  background: url("~@image/order/ok_bg.png") no-repeat #ededed;
  background-size: rem(750) rem(220);
  padding-bottom: rem(300);
}
.static_bg {
  height: rem(180);
  width: rem(750);
  // background: url('../../../../assets/image/dajiao/order/ok_bg.png') no-repeat;
  // background-size: rem(750) rem(220);
  padding-top: rem(48);
  p {
    font-size: rem(40);
    font-weight: bold;
    height: rem(220);
    // line-height: rem(180);
    color: #ffffff;
    margin-left: rem(36);
    b {
      display: block;
      margin-top: rem(6);
      font-size: rem(26);
    }
    span {
      margin-top: rem(6);
      display: block;
      width: rem(140);
      height: rem(44);
      background: #ffffff;
      text-align: center;
      // line-height: rem(44)
      border-radius: rem(22);
      color: #fb4242;
      font-size: rem(30);
      font-weight: bold;
    }
  }
}
.order_info_me {
  // width: rem(484);
}
.order_info {
  width: rem(750);
  margin: 0 auto rem(16);
  padding: rem(40) rem(32) 0;
  box-sizing: border-box;
  height: rem(180);
  background: #ffffff;
  border-radius: rem(40) rem(40) 0px 0px;
  .me {
    align-items: center;
    img {
      width: rem(22); //math.div(33, 1.5)
      height: rem(26); //rem(math.div(39, 1.5));
      display: block;
      margin-right: rem(12);
    }
    span,
    b {
      font-size: rem(30);
      font-weight: bold;
      color: #333333;
      margin-right: rem(24);
    }
  }
  p {
    font-size: rem(22);
    color: #b3b3b3;
    margin-top: rem(10);
  }
}

.middel {
  padding: rem(32);
  background: #fff;
  margin-bottom: rem(16);
  .pro_img {
    width: rem(140);
    height: rem(140);
    border-radius: rem(8);
    display: block;
    margin-right: rem(40);
  }
  .pro_img2 {
    width: rem(100);
    height: rem(100);
    border-radius: rem(8);
    display: block;
    margin-right: rem(40);
  }

  .info {
    width: rem(380);
    margin-right: rem(48);
    h2 {
      font-size: rem(24);
      font-weight: bold;
      color: #333333;
    }
    .h2 {
      font-size: rem(18);
      font-weight: bold;
      color: #333333;
    }
    .sku {
      font-size: rem(22);
      color: #b3b3b3;
      display: block;
      margin-top: rem(2);
    }
    .active {
      display: inline-block;
      margin-top: rem(6);
      height: rem(18);
      line-height: rem(18);
      padding: rem(6);
      text-align: center;
      font-size: rem(18);
      font-weight: bold;
      color: #ff4848;
      background: #ffebeb;
      border-radius: rem(4);
    }
  }
  .price {
    // font-family: 'pfb';
    i {
      // font-size: rem(22);
      font-size: rem(22);
      font-weight: bold;
    }
    p {
      // font-size: rem(30);
      font-size: rem(30);
      font-weight: bold;

      color: #333333;
      display: block;
      text-align: right;
    }
    b {
      // font-family: 'pf';
      text-align: right;
      font-size: rem(22);
      display: block;
      // font-size:rem(22);
      color: #b3b3b3;
    }
  }
}

.detail_price {
  background: #fff;
  // font-size: rem(22);
  padding: rem(34) rem(34) rem(12);
  border: rem(1) solid #ebebeb;
  span {
    // font-family: 'pf';
    font-size: rem(22);
    color: #b3b3b3;
  }
  b {
    // font-family: 'pfb';
    font-size: rem(22);
    font-weight: bold;
    color: #333333;
  }

  .flex-container {
    justify-content: flex-end;
    margin-bottom: rem(34);
    p {
      display: block;
      float: right;
      color: #333333;
      // font-family: 'pfb';
      i {
        // font-size: rem(22);
        font-size: rem(22);
        font-weight: bold;
      }
      b {
        color: #ff4747;
        font-size: rem(30);
        font-weight: bold;

        // font-size: rem(30);
      }
    }
  }
}

.order_id {
  background: #fff;
  padding: rem(40) rem(32);
  .flex-container {
    margin-bottom: rem(36);
  }
  .order_id_li {
    align-items: center;
    p {
      font-size: rem(22);
      color: #b3b3b3;
    }
    span {
      width: rem(58);
      height: rem(28);
      background: #ebebeb;
      border-radius: rem(14);
      font-size: rem(18);
      color: #808080;
      text-align: center;
      line-height: rem(30);
      margin-left: rem(16);
    }
  }
}

.go_mode {
  position: fixed;
  justify-content: flex-end;
  padding: rem(40) rem(44);
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  width: 100%;
  height: rem(168);
  background: #ffffff;
  box-shadow: 0px rem(-4) rem(20) 0px rgba(204, 204, 204, 0.3);
  span {
    margin-left: rem(28);
  }
}

.zengpin {
  display: inline-block;
  width: rem(30);
  height: rem(30);
  line-height: rem(30);
  text-align: center;
  background: #ff4747;
  color: #ffffff;
}
</style>
